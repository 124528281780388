import { takeLatest, takeEvery, call, select, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { fetchFromApi, loadMonitors } from './reducer';
import { push } from '../../global-state/toasts/reducer';
import getFromWorkersApi from '../../utils/fetch/get-from-workers-api';
import postToApi from '../../utils/fetch/post-to-api';
import deleteFromApi from '../../utils/fetch/delete-from-api';

function keyById(objs) {
  return objs.reduce((obj, x) => Object.assign(obj, { [x.id]: x }), {});
}

function* fetchFromApiSaga(action) {
  try {
    // const response = yield call(getFromApi, `channels`);

    // yield put(loadMonitors(keyById(response.monitors)));
    
    const response = yield call(getFromWorkersApi, `all_certificates`);
    
    console.log(response);
  } catch (err) {
    yield put(push({ body: 'Failed to fetch channel data', style: 'error' }));
  }
}

export default function*() {
  yield takeLatest(fetchFromApi.Type, fetchFromApiSaga);
}
