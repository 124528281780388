import createSimpleReducer from '../../utils/redux/create-simple-reducer';
import createAction from '../../utils/redux/create-action';

export const name = 'Repositories';

const defaultState = { view: 0,
  repositories: [],
  
  timerValue: "60",
  timerFunc: null, 
  timer: null};

export const fetchFromApi = createAction(name, 'FETCH');
export const setView = createAction(name, 'SET_VIEW');
export const loadRepositories = createAction(name, 'LOAD_REPOSITORIES');

export const setTimer = createAction(name, 'SET_TIMER');
export const setTimerFunc = createAction(name, 'SET_TIMER_FUNC');

export const getView = (state) => state[name].view;
export const getRepositories = state => state[name].repositories;

export const getTimerValue = state => state[name].timerValue;
export const getTimerFunc = state => state[name].timerFunc;

const reducer = createSimpleReducer(name, defaultState, {
  [setView.Type]: (s, p) => ({ ...s, view: p }),
  [loadRepositories.Type]: (s, p) => ({ ...s, repositories: p}),
  [setTimer.Type]: (s, p) => ({ ...s, timer: p }),
  [setTimerFunc.Type]: (s, p) => ({ ...s, timerFunc: p }),
  });
  
export default reducer;
