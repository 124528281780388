import * as React from 'react';
import DateTime from 'react-datetime';

const ChannelPopup = props => {
  const { dirtyKeys, name, start_date, end_date, setEditChannelId, update, submit, popupLoading,
    setNewChannel, newChannel } = props;
    
  const dirtyStyle = { borderColor: 'orange' };

  function inputProps(key) {
    return {
      style: dirtyKeys.includes(key) ? dirtyStyle : null,
      value: props[key],
      onChange: event => update(key, event.target.value)
    };
  }

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            <span className="has-text-grey-light">Channel</span>
          </p>
          <button className="delete" aria-label="close" onClick={() => 
            { newChannel ? setNewChannel(false) : setEditChannelId(-1)}} />
        </header>
        
        <section className="modal-card-body">
          <div className="form">
            <div className="field">
              <label className="for-form-label">Name</label>
              <div className="control">
                <input className="input" type="text" placeholder="Channel Name" {...inputProps('name')} />
              </div>
            </div>
            
            <div className="field">
              <label className="for-form-label">Data Type</label>
              <div className="control">
                <select className="button filter-select" {...inputProps('string')}>
                  <option value="string">String</option>
                </select>
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={() => submit()}>
            Save changes
          </button>
          <button className="button" onClick={() => { newChannel ? setNewChannel(false) : setEditChannelId(-1)} }>
            Cancel
          </button>
        </footer>
      </div>
      
      <div className={`maps-overlay maps-standard ${ popupLoading ? "" : "maps-hidden" }`}>&nbsp;</div>
    </div>
  );
};
export default ChannelPopup;
