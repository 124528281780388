import createFormState from '../../../../utils/redux/create-form-state';
import createAction from '../../../../utils/redux/create-action';

export const name = 'ChannelPopup';
export const { reducer, types, actions, selectors } = createFormState(
  name,
  {
    name: '',
    data_type: 'string',
  },
);

export default reducer;
