import { fork, put, select } from 'redux-saga/effects';
import {delay} from 'redux-saga/effects'
import { getAll, pop } from './reducer';

function* popToastsLoop() {
  while (true) {
    yield delay(2000);
    const toasts = yield select(getAll);
    if (toasts.length > 0) {
      yield put(pop());
    }
  }
}

export default function*() {
  yield fork(popToastsLoop);
}
