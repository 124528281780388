const createSimpleReducer = (name, defaultValues, actionHandlers) => {
  function reducer(state = defaultValues, action) {
    const handler = actionHandlers[action.type];
    if (handler !== undefined) {
      return handler(state, action.payload);
    }
    return state;
  }
  return reducer;
};
export default createSimpleReducer;
