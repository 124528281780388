import * as React from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import TransitionGroup from 'react-transition-group/TransitionGroup';

import './style.scss';

export default props => {
  const Toast = ({ body, style = 'primary' }) => (
    <div className={`toast notification is-${style}`}>
      {/* <button className="delete" /> */}
      {body}
    </div>
  );

  return (
    <div className="toast-container">
      <TransitionGroup>
        {props.toasts.reverse().map(_ => (
          <CSSTransition key={_.time} classNames="fade" timeout={500}>
            <Toast body={_.body} style={_.style} />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};
