import createSimpleReducer from './create-simple-reducer';
import createAction from './create-action';

function createFormState(name, defaultValues, _actions = {}, _reducers = {}) {
  const keys = Object.keys(defaultValues);
  const actions = {
    // true actions
    replace: createAction(name, 'replace'),
    update: createAction(name, 'update', (key, value) => {
      if (!keys.includes(key)) {
        throw Error(`Key ${key} not in form ${keys}`);
      }
      return { key, value };
    }),
    reset: createAction(name, 'reset'),
    // side-effectful actions
    submit: createAction(name, 'submit'),
    success: createAction(name, 'success'),
    request: createAction(name, 'request'),
    ..._actions
  };
  const reducer = createSimpleReducer(name, defaultValues, {
    [actions.update.Type]: (state, payload) =>
      Object.assign({}, state, { [payload.key]: payload.value }),
    [actions.replace.Type]: (state, payload) => Object.assign({}, defaultValues, payload),
    [actions.reset.Type]: () => defaultValues,
    ..._reducers
  });

  const selectors = {
    getKey: (state, key) => {
      if (!keys.includes(key)) {
        throw Error(`Key ${key} not in form ${keys}`);
      }
      return state[name][key];
    },
    getAll: state => state[name],
    isDirty: (state, key) => {
      if (!keys.includes(key)) {
        throw Error(`Key ${key} not in form ${keys}`);
      }
      return state[name][key] === defaultValues[key];
    },
    dirtyKeys: state => keys.filter(key => state[name][key] !== defaultValues[key])
  };
  return {
    reducer,
    actions,
    selectors,
    name
  };
}
export default createFormState;
