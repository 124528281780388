import * as React from 'react';
import { Route } from 'react-router-dom';



import SignIn from '../containers/sign-in';

const LoginForm = () => (
  <section className="login-hero">
    <div className="level">
      <div className="level-item">
        <p className="login-title">
          RELENTLESS SYSTEM MONITORING
        </p>
        <div className="login-box box">
    
          <Route path="/login" exact component={() => <SignIn />} />
        </div>
      </div>
    </div>
  </section>
);
export default LoginForm;
