
import { takeLatest, takeEvery, select, call, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { actions, selectors } from './reducer';

import getFromApi from '../../../../utils/fetch/get-from-api';
import postToApi from '../../../../utils/fetch/post-to-api';
import patchToApi from '../../../../utils/fetch/patch-to-api';

import Moment from 'moment';

import { push } from '../../../../global-state/toasts/reducer';
import { setEditChannelId, getEditChannelId, setPopupLoading, setNewChannel,
  fetchFromApi } from '../../reducer';

function * requestFromApiSaga(action) {
  try {
    const id = yield select(getEditChannelId);
    
    // Clear fields or set to defaults
    const fields = yield select(selectors.getAll);
    fields.name = '';
    fields.data_type = 'string';
    
    if (id >= 0) {
      yield put(setPopupLoading(true));
    
      var channelData = yield call(getFromApi, `channels/${id}`);
      
      yield put(actions.replace(channelData.channel));
      
      yield put(setPopupLoading(false));
    }
  } catch (err) {
    yield put(push({ body: 'Failed to fetch channel data', style: 'danger' }));
  }
}

function isBlank(str) {
  return (!str || /^\s*$/.test(str));
}

function* submitFormSaga() {
  
    const id = yield select(getEditChannelId);
    const fields = yield select(selectors.getAll);
  
    // Do basic validations
    var pass = true;
    
    if (isBlank(fields.name)) {
      pass = false;
      yield put(push({ body: 'Channel name cannot be blank', style: 'warning' }));
    }

    if (pass) {
      var submission = {...fields};
      
      submission.name = fields.name;
      submission.data_type = fields.data_type;
	  
      if (id == -1) {	
	
		var response = yield call(postToApi, `channels`, submission);
	  
	    if (response.success == "true")
	    {
		  yield put(setNewChannel(false));
		}
		else if (response.success == "too_many") {
		  yield put(push({ body: 'Project limit for organization exceeded', style: 'danger' }));
		}
		
	  }
	  else {
		yield call(patchToApi, `channels/${id}`, submission);
	  
		// Close the edit modal
		yield put(setEditChannelId(-1));
	  }    
	
	  // And refresh the dashboard
	  yield put(fetchFromApi());
	}
    
try {    
    
  } catch (err) {
    yield put(push({ body: 'Failed to update channel', style: 'danger' }));
  }
}

export default function*() {
  yield takeEvery(actions.request.Type, requestFromApiSaga);
  yield takeLatest(actions.submit.Type, submitFormSaga);
}

