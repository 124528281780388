import createSimpleReducer from '../../utils/redux/create-simple-reducer';
import createAction from '../../utils/redux/create-action';

export const name = 'Terminal';

const defaultState = { view: 0, line: ""
  };

export const fetchFromApi = createAction(name, 'FETCH');

export const setView = createAction(name, 'SET_VIEW');
export const setLine = createAction(name, 'SET_LINE');

export const getView = (state) => state[name].view;
export const getLine = (state) => state[name].line;

const reducer = createSimpleReducer(name, defaultState, {
  [setView.Type]: (s, p) => ({ ...s, view: p }),
  [setLine.Type]: (s, p) => ({ ...s, line: p }),
  });
  
export default reducer;
