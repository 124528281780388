import * as React from 'react';

const withComponentDidUnmount = callback => Component => {
  class WrappedComponent extends React.PureComponent {
    componentWillUnmount() {
      callback(this.props);
    }
    render() {
      return <Component {...this.props} />;
    }
  }
  return WrappedComponent;
};
export default withComponentDidUnmount;
