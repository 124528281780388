import createSimpleReducer from '../../utils/redux/create-simple-reducer';
import createAction from '../../utils/redux/create-action';

export const name = 'Channels';

const defaultState = { view: 0,
  channels: [],
  newChannel: false,
  editChannelId: -1,
  
  popupLoading: false,
  
  timerValue: "60",
  timerFunc: null, 
  timer: null};

export const fetchFromApi = createAction(name, 'FETCH');
export const setView = createAction(name, 'SET_VIEW');
export const loadChannels = createAction(name, 'LOAD_CHANNELS');

export const setTimer = createAction(name, 'SET_TIMER');
export const setTimerFunc = createAction(name, 'SET_TIMER_FUNC');
export const setNewChannel = createAction(name, 'SET_NEW_CHANNEL');
export const setEditChannelId = createAction(name, 'SET_NEW_CHANNEL');

export const setPopupLoading = createAction(name, 'SET_POPUP_LOADING');

export const getView = (state) => state[name].view;
export const getChannels = state => state[name].channels;
export const getNewChannel = state => state[name].newChannel;
export const getEditChannelId = state => state[name].editChannelId;

export const getPopupLoading = state => state[name].popupLoading;

export const getTimerValue = state => state[name].timerValue;
export const getTimerFunc = state => state[name].timerFunc;

const reducer = createSimpleReducer(name, defaultState, {
  [setView.Type]: (s, p) => ({ ...s, view: p }),
  [loadChannels.Type]: (s, p) => ({ ...s, channels: p}),
  [setNewChannel.Type]: (s, p) => ({ ...s, newChannel: p }),
  [setEditChannelId.Type]: (s, p) => ({ ...s, editChannelId: p }),
  [setTimer.Type]: (s, p) => ({ ...s, timer: p }),
  [setTimerFunc.Type]: (s, p) => ({ ...s, timerFunc: p }),
  
  [setPopupLoading.Type]: (s, p) => ({ ...s, popupLoading: p }),
  });
  
export default reducer;
