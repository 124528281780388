import createSimpleReducer from '../../utils/redux/create-simple-reducer';
import createAction from '../../utils/redux/create-action';

export const name = 'Certificates';

const defaultState = { view: 0,
  certificates: [],
  statuses: [],
  
  timerValue: "60",
  timerFunc: null, 
  timer: null,
  gotData: false
};

export const fetchFromApi = createAction(name, 'FETCH');
export const setView = createAction(name, 'SET_VIEW');
export const setGotData = createAction(name, 'SET_GOT_DATA');
export const loadCertificates = createAction(name, 'LOAD_CERTIFICATES');
export const loadStatuses = createAction(name, 'LOAD_STATUSES');

export const setTimer = createAction(name, 'SET_TIMER');
export const setTimerFunc = createAction(name, 'SET_TIMER_FUNC');

export const getView = (state) => state[name].view;
export const getGotData = (state) => state[name].gotData;
export const getCertificates = state => state[name].certificates;
export const getStatuses = state => state[name].statuses;

export const getTimerValue = state => state[name].timerValue;
export const getTimerFunc = state => state[name].timerFunc;

const reducer = createSimpleReducer(name, defaultState, {
  [setView.Type]: (s, p) => ({ ...s, view: p }),
  [setGotData.Type]: (s, p) => ({ ...s, gotData: p }),
  [loadCertificates.Type]: (s, p) => ({ ...s, certificates: p}),
  [loadStatuses.Type]: (s, p) => ({ ...s, statuses: p}),
  [setTimer.Type]: (s, p) => ({ ...s, timer: p }),
  [setTimerFunc.Type]: (s, p) => ({ ...s, timerFunc: p }),
  });
  
export default reducer;
