import { takeLatest, takeEvery, call, select, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { fetchFromApi, loadCertificates, loadStatuses, setGotData } from './reducer';
import { push } from '../../global-state/toasts/reducer';
import getFromWorkersApi from '../../utils/fetch/get-from-workers-api';
import postToApi from '../../utils/fetch/post-to-api';
import deleteFromApi from '../../utils/fetch/delete-from-api';
import Moment from 'moment';

function keyById(objs) {
  return objs.reduce((obj, x) => Object.assign(obj, { [x.id]: x }), {});
}

function* fetchFromApiSaga(action) {
  
  try {
    const response = yield call(getFromWorkersApi, `all_certificates`);
    
    var certificates = [];
    var statuses = [];
    
    Object.keys(response.all_certs).forEach(_ => {
      var host = _;
      var data = response.all_certs[_].certs;
      
      Object.keys(data).forEach(__ => {
        var site = __.split("/").slice(-2)[0];
        var date = data[__].substr(9);
        
        var m = Moment(date, "MMM DD HH:mm:ss YYYY");
        date = m.format("YYYY/MM/DD");
        
        var exceeded = "False";
        
        const today = Moment();
        if (today > m) {
          exceeded = "True";
        }
        
        if (site != "end") {
          certificates.push({address: site, date: date, host: host, exceeded: exceeded});
        }
      });
    });

    Object.keys(response.all_sites).forEach(_ => {
      statuses.push({address: _, status: response.all_sites[_]});
    });
    
    //console.log(certificates);
    
    yield put(loadCertificates(certificates));
    yield put(loadStatuses(statuses));

    yield put(setGotData(true));
    
  } catch (err) {
    yield put(setGotData(false));

    yield put(push({ body: 'Failed to fetch certificate data', style: 'error' }));
  }
}

export default function*() {
  yield takeLatest(fetchFromApi.Type, fetchFromApiSaga);
}
