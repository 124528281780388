import { connect } from 'react-redux';
import { fetchFromApi, getView, setView, 
  getMonitors, getTimerValue, setTimerFunc, getTimerFunc, getTimer, setTimer,
  } from './reducer';
import Component from './components/monitors';

export default connect(
  (state, ownProps) => ({
    monitors: getMonitors(state),
    view: getView(state),
    timerValue: getTimerValue(state),
  }),
  dispatch => ({ 
  	fetchFromApi: () => dispatch(fetchFromApi(1)), 
  	setView: (view) => dispatch(setView(view)),
  	setTimer: (timer) => dispatch(setTimer(timer)),
  	setTimerFunc: (timer) => dispatch(setTimerFunc(timer)),
  })
)(Component);
