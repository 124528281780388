import { takeLatest, call, put, select } from 'redux-saga/effects';
import {delay} from 'redux-saga/effects'
import { actions, selectors } from './reducer';

import { logIn, logOut } from '../../../../global-state/authentication/reducer';
import { push } from '../../../../global-state/toasts/reducer';
import { redirectTo, getEntryLocation } from '../../../../global-state/navigation/reducer';

import parseJwt from '../../../../utils/parse-jwt';
import postToApi from '../../../../utils/fetch/post-to-api';
import loginToApiWorkers from '../../../../utils/fetch/login-to-api-workers';

function* restoreLocation() {
  const entryRoute = yield select(getEntryLocation);
  let pathname = '/system_status';
  
  /*
  if (!(entryRoute === null || entryRoute === undefined) && entryRoute.pathname !== "/login"){
    pathname = entryRoute.pathname;
  }
  */ 
  
  yield put(redirectTo(pathname));
}

/*
  Submit login form to API for authentication
*/
function* submitLoginForm() {
  let message = "";
    const form = yield select(selectors.getAll);

    let submission = {
      username: form.userName,
      password: form.password,
      //platform: "terminal2"
    };

    const response = yield call(loginToApiWorkers, 'login', submission);


    if(response.message !== undefined){
      message = response.message;
      yield put(push({ body: response.message }));
    } else{
      const token = response.token;
      localStorage.token = token;


      //const tokenContents = parseJwt(token);
      yield put(logIn({ token }));

      yield call(restoreLocation);
    }
  try {
  } catch (err) {
    yield put(push({ body: 'Failed to sign' + (message !== "" ? ": " + message : "") }, err));
    yield put(redirectTo('/login'));
  }
}

/*
  Restore logged in user's session.
*/
function* restoreloggedInState() {
  let token = localStorage.token;
  if(token !== undefined){
    
    yield put(logIn({ token }));
    yield call(restoreLocation);
  }
}

/*
  Clear the tokens from localstorage.
*/
function* logOutSaga() {
  yield delay(1);
  localStorage.removeItem('token');
}

/*
  Submit forgot password form to the API.
*/
function* submitForgotPassword() {
  try {
    const form = yield select(selectors.getAll);
    if (!form.userName) {
      yield put(push({ body: 'Please enter a valid email address', style: 'warning' }));
      return;
    }
    call(postToApi, `public/forgotPassword/${encodeURIComponent(form.userName)}`);
    yield put(push({ body: 'You will receive an email with further instructions' }));
    yield put(redirectTo('/login'));
  } catch (err) {
    yield put(
      push(
        { body: 'Failed to request password reset. Please try again later.', style: 'danger' },
        err
      )
    );
  }
}

export default function*() {
  yield call(restoreloggedInState);
  yield takeLatest(actions.submit.Type, submitLoginForm);
  yield takeLatest(actions.forgotPassword.Type, submitForgotPassword);
  yield takeLatest(logOut.Type, logOutSaga);
}
