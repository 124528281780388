import createSimpleReducer from '../../utils/redux/create-simple-reducer';
import createAction from '../../utils/redux/create-action';

export const name = 'Cameras';

const defaultState = { view: 0, 
  ref: null, 
  scene: null, 
  camera: null, 
  position: {x: 0, y: 0, z: 0, rx: 0, ry: 0, rz: 0},
  console_live: false,
  camera_live: false,
  boundaries: [],
  selectedBoundaryId: -1,
  cameras: [],
  images: [],
  };

export const fetchFromApi = createAction(name, 'FETCH');

export const setView = createAction(name, 'SET_VIEW');
export const setRef = createAction(name, 'SET_REF');
export const startRender = createAction(name, 'START_RENDER');

export const setBoundaries = createAction(name, 'SET_BOUNDARIES');
export const setSelectedBoundaryId = createAction(name, 'SET_SELECTED_BOUNDARY_ID');
export const setCamera = createAction(name, 'SET_CAMERA');
export const setVisibleCameras = createAction(name, 'SET_VISIBLE_CAMERAS');
export const setVisibleImages = createAction(name, 'SET_VISIBLE_IMAGES');

export const setScene = createAction(name, 'SET_SCENE');
export const setPosition = createAction(name, 'SET_POSITION');

export const setConsoleLive = createAction(name, 'SET_CONSOLE_LIVE');
export const setCameraLive = createAction(name, 'SET_CAMERA_LIVE');

export const handleKeyDownEvent = createAction(name, 'HANDLE_KEY_DOWN_EVENT');
export const handleKeyUpEvent = createAction(name, 'HANDLE_KEY_UP_EVENT');

export const getView = (state) => state[name].view;
export const getRef = (state) => state[name].ref;

export const getBoundaries = (state) => state[name].boundaries;
export const getSelectedBoundaryId = (state) => state[name].selectedBoundaryId;
export const getCamera = (state) => state[name].camera;
export const getVisibleCameras = (state) => state[name].cameras;
export const getVisibleImages = (state) => state[name].images;

export const getScene = (state) => state[name].scene;
export const getPosition = (state) => state[name].position;

export const getConsoleLive = (state) => state[name].console_live;
export const getCameraLive = (state) => state[name].camera_live;

const reducer = createSimpleReducer(name, defaultState, {
  [setView.Type]: (s, p) => ({ ...s, view: p }),
  [setRef.Type]: (s, p) => ({ ...s, ref: p }),
  [setScene.Type]: (s, p) => ({ ...s, scene: p }),
  
  [setCamera.Type]: (s, p) => ({ ...s, camera: p }),
  [setVisibleCameras.Type]: (s, p) => ({ ...s, cameras: p }),
  [setVisibleImages.Type]: (s, p) => ({ ...s, images: p }),
  [setBoundaries.Type]: (s, p) => ({ ...s, boundaries: p }),
  [setSelectedBoundaryId.Type]: (s, p) => ({ ...s, selectedBoundaryId: p }),
  
  [setPosition.Type]: (s, p) => ({ ...s, position: p }),
  
  [setConsoleLive.Type]: (s, p) => ({ ...s, console_live: p }),
  [setCameraLive.Type]: (s, p) => ({ ...s, camera_live: p }),
  });
  
export default reducer;
