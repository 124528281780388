import * as React from 'react';

import { Link } from 'react-router-dom';
import L2 from '../../terminal/containers/L2'

const items = [
  /*{
    name: 'CAMERAS',
    url: '/cameras'
  },*/
  /*
  {
    name: 'TERMINAL',
    url: '/terminal'
  },*/
  {
    name: 'SYSTEM STATUS',
    url: '/system_status'
  },
  /*
  {
    name: 'CHANNELS',
    url: '/channels'
  },
  */
  {
    name: 'LOG OUT',
    url: '/logout'
  },
];

const rightItems = [
  {
    name: '',
    url: '',
    divider: true
  },
  {
    name: 'Log out',
    url: '/logout',
    divider: false
  }
];

export default class extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { foldedOnMobile: true, accountMenu: false };
    this.toggle = this.toggle.bind(this);
    this.toggleAccountMenu = this.toggleAccountMenu.bind(this);
  }
  
  toggle() {
    this.setState({ foldedOnMobile: !this.state.foldedOnMobile });
  }
  
  toggleAccountMenu() {
    this.setState({ accountMenu: !this.state.accountMenu });
  }
  
  render() {
    var url = document.URL.split("/");
    url = url[url.length - 1];
    
    const activeClasses = this.state.foldedOnMobile ? '' : ' is-active';
    
    const activeAccountMenu = this.state.accountMenu ? 'is-active' : '';
    
    return (
      <nav className="navbar">
        <div className="navbar-brand">
          <span style={{padding: "0px", marginLeft: "20px", marginRight: "10px"}} className="navbar-item">
            { /*<svg width="50" height="50" viewBox="0 0 100 100">
              <circle cx="25" cy="50" r="18" stroke="black" strokeWidth="4" fill="#00000000"/>
              <line x1="25" y1="60" x2="25" y2="78" stroke="black" strokeWidth="4" strokeLinecap="round"/>
              <line x1="30" y1="58" x2="40" y2="73" stroke="black" strokeWidth="4" strokeLinecap="round"/>
              <circle cx="75" cy="50" r="18" stroke="black" strokeWidth="4" fill="#00000000"/>
              <line x1="50" y1="10" x2="50" y2="90" stroke="black" strokeWidth="4" strokeLinecap="round"/>
            </svg> */ }
            <L2 word="oso" color="#00FF00"/>
          </span>
          <a
            role="button"
            className={`navbar-burger burger ${activeClasses}`}
            aria-label="menu"
            aria-expanded={!this.state.foldedOnMobile}
            onKeyDown={this.toggle}
            onClick={this.toggle}
            tabIndex={0}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div className={`navbar-menu ${activeClasses}`}>
          <ul className="navbar-start">
            {items.map(item => (
              <Link to={item.url} as="li" className={`navbar-item is-hoverable ${("/" + url) == item.url ? "navbar-selected" : ""} `} key={item.url}>
                {item.name}
              </Link>
            ))}
          </ul>
        </div>
      </nav>
    );
  }
}
