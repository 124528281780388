import * as React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import { DragDropContext } from 'react-beautiful-dnd';

import withComponentDidMount from '../../../utils/react/with-component-did-mount';
import withComponentDidUnmount from '../../../utils/react/with-component-did-unmount';

const Monitors = (props) => {

  var list = Object.values(props.monitors).sort((a, b) => (a.color < b.color) ? 1 : -1);
  
  console.log(list);
  
  const columns = [{
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Name',
    accessor: 'name' // String-based value accessors!
  },
  {
    Header: 'Data Type',
    accessor: 'data_type' // String-based value accessors!
  },
  {
    Header: 'Table Name',
    accessor: 'table_name' // String-based value accessors!
  },
  ];


  return (
  <section className="section">
   
    <main className="monitors">
       
      <div>
      
        <ReactTable
          data={list}
          columns={columns}
          getTrProps={(state, rowInfo, column) => {
            return {
              style: {
                background: rowInfo !== undefined ? (rowInfo.row.color !== null && rowInfo.row.color != "" ? rowInfo.row.color : "#00000000") : "#00000000"
              }
            }
          }}
        />
      </div>
    </main>
  </section>
  );
};

export default withComponentDidUnmount(props => { clearInterval(props.timer); })(withComponentDidMount(props => { props.fetchFromApi(); props.setTimerFunc(() => { props.fetchFromApi(); console.log('Refresh');} ); props.setTimer(setInterval(() => {props.fetchFromApi(); console.log('Refresh');}, 1000 * parseInt(props.timerValue)));})(Monitors));

