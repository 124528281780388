import { takeEvery } from 'redux-saga/effects';
import { redirectTo } from './reducer';

import browserHistory from '../../store/browser-history';

function* redirect(action) {
  const url = action.payload;
  browserHistory.push(url);
  yield 1;
}

export default function*() {
  yield takeEvery(redirectTo.Type, redirect);
}
