import { put } from 'redux-saga/effects';
import { push } from '../../global-state/toasts/reducer';
import { logOut } from '../../global-state/authentication/reducer';
import { select } from "redux-saga/effects"

function* getFromWorkersApi(route) {

  const token = yield select(state => state.Authentication.token);
  
  
  const response = yield fetch(document.homeAddressWorkers + `${route}`, {
    method: 'GET',
    headers: { 'Accept' : 'application/json', 'Content-Type': 'application/json;', 'Authorization': token },
    credentials: 'include'
  })
  .then(r => {
    if (r.ok) {

      return r.json();
    }
    return { $error: r.status };
  })
  .catch(err => 
    console.error('error', err)
  );
  if (response.$error) {
    if (response.$error === 401 || response.$error === 403) {
      yield put(
        push({
          type: 'Toasts/PUSH',
          payload: { body: 'You have been logged out', style: 'warning' }
        })
      );
      yield put(logOut());
    }
    throw Error();
  }
  return response;
  
  return {};
}
export default getFromWorkersApi;
