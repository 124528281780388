import cameras from '../containers/cameras/sagas';
import certificates from '../containers/certificates/sagas';
import channels from '../containers/channels/sagas';
import channelPopup from '../containers/channels/containers/channel-popup/sagas';
import monitors from '../containers/monitors/sagas';
import repositories from '../containers/repositories/sagas';
import terminal from '../containers/terminal/sagas';
import loginContainersSignIn from '../containers/login/containers/sign-in/sagas';

import navigation from '../global-state/navigation/sagas';
import toasts from '../global-state/toasts/sagas';

export default [
  cameras,
  certificates,
  channels,
  channelPopup,
  monitors,
  repositories,
  terminal,

  loginContainersSignIn,

  navigation,
  toasts
];


