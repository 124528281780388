import { takeLatest, takeEvery, call, select, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { fetchFromApi, loadRepositories } from './reducer';
import { push } from '../../global-state/toasts/reducer';
import getFromWorkersApi from '../../utils/fetch/get-from-workers-api';
import postToApi from '../../utils/fetch/post-to-api';
import deleteFromApi from '../../utils/fetch/delete-from-api';
import Moment from 'moment';

function keyById(objs) {
  return objs.reduce((obj, x) => Object.assign(obj, { [x.id]: x }), {});
}

function* fetchFromApiSaga(action) {
  
    const response = yield call(getFromWorkersApi, `all_repositories`);
    
    var repositories = Object.values(response);
    
    //console.log(repositories);
    
    yield put(loadRepositories(repositories));
    
  try {
  } catch (err) {
    yield put(push({ body: 'Failed to fetch repository data', style: 'error' }));
  }
}

export default function*() {
  yield takeLatest(fetchFromApi.Type, fetchFromApiSaga);
}
