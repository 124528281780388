import * as React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import { DragDropContext } from 'react-beautiful-dnd';

import L2 from '../containers/L2'

import withComponentDidMount from '../../../utils/react/with-component-did-mount';
import withComponentDidUnmount from '../../../utils/react/with-component-did-unmount';

const Terminal = (props) => {

  return (
  <section className="section">
    <main className="terminal">
      <div>
        { props.line.split(" ").map(_ =>
          
          <L2 word={_} />
          
          )}
      
        {/*
        <L2 word="oso" />
        <L2 word="oco" />
        <L2 word="usu" />
        
        <L2 word="o" />
        */}
      </div>
      
      <div className="entry-console">
        <input className="entry-input" type="text" 
          value={props.line} 
          onKeyPress={(event) => (event.key === "Enter") ? "" : "" }
          onChange={(event) => props.setLine(event.target.value)} />
      </div>
    </main>
  </section>
  );
};

export default withComponentDidUnmount(props => { })(withComponentDidMount(props => { })(Terminal));

// 