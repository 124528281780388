import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createMyStore } from './store';
import browserHistory from './store/browser-history';
import Routes from './containers/routes';
import { ProgressBar } from 'react-fetch-progressbar';

import './App.sass';
import './App.css';

import { progressBarFetch, setOriginalFetch } from 'react-fetch-progressbar';
 
// Let react-fetch-progressbar know what the original fetch is.
setOriginalFetch(window.fetch);
 
/* 
  Now override the fetch with progressBarFetch, so the ProgressBar
  knows how many requests are currently active.
*/
window.fetch = progressBarFetch;

document.homeAddress = "https://feed.ausonet.org:8889/";   
document.homeAddressWorkers = "https://workers.ausonet.org/";   
document.title = "Ausonet System Monitoring";

const App = props => (
  <React.Fragment>
    <ProgressBar />
    <Provider store={props.store}>
      <Routes history={browserHistory} />
    </Provider>
  </React.Fragment>
);

const store = createMyStore();
const body = document.getElementsByTagName('body')[0];
const mount = body.appendChild(document.createElement('div', { id: 'mount' }));
ReactDOM.render(<App store={store} />, mount);

