import * as React from 'react';
import { Router, Route, Redirect } from 'react-router-dom';

import Cameras from '../../cameras/index';
import Certificates from '../../certificates/index';
import Channels from '../../channels/index';
import Monitors from '../../monitors/index';
import Repositories from '../../repositories/index';
import Terminal from '../../terminal/index';

import Login from '../../login/index';
import Navigation from '../../navigation/index';
import Toasts from '../../toasts/index';
import ErrorBoundary from '../../../utils/react/error-boundary';
import history from '../../../store/browser-history';

import { ConnectedRouter } from 'connected-react-router';

const Routes = props => {
  const PrivateRoute = ({ component, ...rest }) => (
    <Route
      {...rest}
      component={
        props.isLoggedIn
          ? () => <ErrorBoundary>{component()}</ErrorBoundary>
          : () => <Redirect to="/login/" />
      }
    />
  );
  return (
    <div>
      <ConnectedRouter history={history}>
        <Route path="/">
          <div>
            {/* these should appear in every page */}
            {props.isLoggedIn ? (
              <Route
                path="/"
                component={({ location }) => <Navigation url={location.pathname} />}
              />
            ) : (
              <Route path="/login" component={() => <Login />} />
            )}
            <Route path="/" component={() => <Toasts />} />

            {/* sections */}
            {/* <PrivateRoute path="/cameras" component={() => <Cameras />} /> */ }
            <PrivateRoute path="/system_status" component={() => <Certificates />} />
            <PrivateRoute path="/channels" component={() => <Channels />} />
            <PrivateRoute path="/monitors" component={() => <Monitors />} />
            <PrivateRoute path="/repositories" component={() => <Repositories />} />
            <PrivateRoute path="/terminal" component={() => <Terminal />} />
            
            {/* default to dashboard */}
            <Route exact path="/" component={() => <Redirect to="/system_status" />} />

            {/* logout is a shortcut to the logout event */}
            <Route
              path="/logout"
              component={() => {
                props.logOut();
                return <Redirect to="/login" />;
              }}
            />
          </div>
        </Route>
      </ConnectedRouter>
    </div>
  );
};
export default Routes;
