import { connect } from 'react-redux';
import { fetchFromApi, getView, setView, getRef, setRef,
  handleKeyDownEvent, handleKeyUpEvent, getPosition, setConsoleLive,
  getConsoleLive, getCameraLive, setCameraLive, getVisibleCameras,
  startRender, getVisibleImages, setSelectedBoundaryId, getSelectedBoundaryId,
  getBoundaries } from './reducer';
import Component from './components/cameras';

export default connect(
  (state, ownProps) => ({
    view: getView(state),
    ref: getRef(state),
    position: getPosition(state),
    cameras: getVisibleCameras(state),
    boundaries: getBoundaries(state),
    selectedBoundaryId: getSelectedBoundaryId(state),
    images: getVisibleImages(state),
    consoleLive: getConsoleLive(state),
    cameraLive: getCameraLive(state),
  }),
  dispatch => ({ 
  	fetchFromApi: () => dispatch(fetchFromApi(1)), 
  	setRef: (ref) => dispatch(setRef(ref)),
  	handleKeyDownEvent: (event) => dispatch(handleKeyDownEvent(event)),
  	handleKeyUpEvent: (event) => dispatch(handleKeyUpEvent(event)),
  	setConsoleLive: (live) => dispatch(setConsoleLive(live)),
  	setCameraLive: (live) => dispatch(setCameraLive(live)),
  	startRender: () => dispatch(startRender()),
  	setSelectedBoundaryId: (id) => dispatch(setSelectedBoundaryId(id)),
  })
)(Component);
