import 'babel-polyfill'; // eslint-disable-line
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';

import rootReducer from './reducer';
import rootSagas from './sagas';
import { routerMiddleware } from 'connected-react-router'

export const history = createBrowserHistory();

export const createMyStore = () => {

  const sagaMiddleware = createSagaMiddleware();
  /*
        Find all state.js files throughout the project and map them to the state tree
    */

  const store = createStore(rootReducer(history), composeWithDevTools(applyMiddleware(sagaMiddleware, routerMiddleware(history))));

  /* this must happen before the sagas turn on, so that we can redirect users
      to the url they entered, in case they are not logged in */
  store.dispatch({ type: 'URL_ENTRY', payload: history.location });
  store.dispatch({ type: 'URL_CHANGE', payload: history.location });
  /* listen for changes to the browserHistory */
  history.listen(event => {
    store.dispatch({ type: 'URL_CHANGE', payload: event });
  });

  /*
        Find all saga.js files throughout the project then run the default sagas
        This must happen after mounting the saga middleware
    */
  rootSagas.map(saga => sagaMiddleware.run(saga));

  return store;
};
