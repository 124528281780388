import createSimpleReducer from '../../utils/redux/create-simple-reducer';
import createAction from '../../utils/redux/create-action';

export const name = 'Toasts';

export const push = createAction(name, 'PUSH');
export const pop = createAction(name, 'POP');

export const getAll = state => state[name];

export const reducer = createSimpleReducer(name, [], {
  [push.Type]: (s, p) => [{ ...p, time: Date.now() }, ...s],
  [pop.Type]: s => s.filter(_ => Date.now() - _.time < 3000)
});
export default reducer;
