import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import * as cameras from '../containers/cameras/reducer';
import * as certificates from '../containers/certificates/reducer';
import * as channels from '../containers/channels/reducer';
import * as channelPopup from '../containers/channels/containers/channel-popup/reducer';
import * as monitors from '../containers/monitors/reducer';
import * as repositories from '../containers/repositories/reducer';
import * as terminal from '../containers/terminal/reducer';

import * as login from '../containers/login/containers/sign-in/reducer';

import * as authentication from '../global-state/authentication/reducer';
import * as navigation from '../global-state/navigation/reducer';
import * as toasts from '../global-state/toasts/reducer';

const reducersDict = [
  cameras,
  certificates,
  channels,
  channelPopup,
  monitors,
  repositories,
  terminal,

  login,
  authentication,
  navigation,
  toasts
].reduce((prev, curr) => Object.assign({}, prev, { [curr.name]: curr.default }), {});

const appReducer = (history) => combineReducers({ ...reducersDict, router: connectRouter(history) });

const rootReducer = (history) => (state, action ) => {
  if ( action.type === 'Authentication/LOGGED_OUT' ) {
    state = undefined;
  }
      
  return appReducer(history)(state, action)
}

export default rootReducer;




