import * as React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import { DragDropContext } from 'react-beautiful-dnd';

import withComponentDidMount from '../../../utils/react/with-component-did-mount';
import withComponentDidUnmount from '../../../utils/react/with-component-did-unmount';
import { Color } from 'three';

const Certificates = (props) => {

  var list = Object.values(props.certificates).sort((a, b) => (a.date > b.date) ? 1 : -1);
  var listStatuses = Object.values(props.statuses).sort((a, b) => (a.status < b.status) ? 1 : -1);

  const columns = [
  {
    Header: 'SITE ADDRESS',
    accessor: 'address' // String-based value accessors!
  },
  {
    Header: 'EXPIRATION DATE',
    accessor: 'date' // String-based value accessors!
  },
  {
    Header: 'HOST',
    accessor: 'host' // String-based value accessors!
  },
  {
    Header: 'EXCEEDED',
    accessor: 'exceeded' // String-based value accessors!
  },
  ];

  const columnsStatus = [
    {
      Header: 'SITE ADDRESS',
      accessor: 'address' // String-based value accessors!
    },
    {
      Header: 'STATUS',
      accessor: 'status' // String-based value accessors!
    },
    ];


  return (
  <section className="section">
   
    <main className="certificates">

      { !props.gotData &&
        <div className="error-bar">
          NOT RECIEVED DATA
        </div> 
      }       

      <div className="left-side">
        <p>CERTIFICATE STATUSES</p>

        <ReactTable
          data={list}
          columns={columns}
          defaultPageSize={100}
          getTrProps={(state, rowInfo, column) => {
            return {
              style: {
                background: rowInfo !== undefined ? (rowInfo.row.exceeded == "True" ? "#FF0000" : "#00000000") : "#00000000",
                color: "#FFFFFF"
              }
            }
          }}
        />
      </div>
      <div className="right-side">
        <p>SITE STATUSES</p>

        <ReactTable
          data={listStatuses}
          columns={columnsStatus}
          defaultPageSize={100}
          getTrProps={(state, rowInfo, column) => {
            return {
              style: {
                background: rowInfo !== undefined ? (rowInfo.row.status != 200 ? "#FF0000" : "#00000000") : "#00000000",
                color: "#FFFFFF"
              }
            }
          }}
        />
      </div>
    </main>
  </section>
  );
};

export default withComponentDidUnmount(props => { clearInterval(props.timer); })(withComponentDidMount(props => { props.fetchFromApi(); console.log('refresh'); props.setTimerFunc(() => { props.fetchFromApi();} ); props.setTimer(setInterval(() => {props.fetchFromApi(); console.log('refresh')}, 1000 * parseInt(props.timerValue)));})(Certificates));

