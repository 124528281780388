import { takeLatest, takeEvery, call, select, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from '../../global-state/toasts/reducer';
import getFromApi from '../../utils/fetch/get-from-api';
import postToApi from '../../utils/fetch/post-to-api';
import deleteFromApi from '../../utils/fetch/delete-from-api';

function keyById(objs) {
  return objs.reduce((obj, x) => Object.assign(obj, { [x.id]: x }), {});
}

export default function*() {
 
}
