function loginToApiWorkers(route, body) {
 
  return (
    fetch(document.homeAddressWorkers + 'login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;'},
      credentials: 'include',
      body: JSON.stringify(body)
    })
      .catch(err => console.error('error', err))
      .then(_ => _.json())
  );
}
export default loginToApiWorkers;
