import * as React from 'react';
import ReactDOM from 'react-dom';

import { takeLatest, takeEvery, call, select, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from '../../global-state/toasts/reducer';
import { getFromApi } from '../../utils/fetch/get-from-api';
import { nonSagaPostToApi, postToApiBlock } from '../../utils/fetch/post-to-api';
import { nonSagaPutToApi, putToApi } from '../../utils/fetch/put-to-api';
import deleteFromApi from '../../utils/fetch/delete-from-api';

import * as THREE from "three";
import { assignUVs, lonLatAltToVector3 } from '../../utils/3dUtils.js';

import L2 from '../terminal/containers/L2/components/l2.js';

import { setRef, getRef, setScene, getScene, setCamera, getCamera,
  handleKeyDownEvent, handleKeyUpEvent,setPosition, getPosition,
  getConsoleLive, setVisibleCameras, startRender, setVisibleImages,
  setBoundaries, setSelectedBoundaryId, getSelectedBoundaryId } from './reducer';

import { OrbitControls } from '../../utils/OrbitControls';
import { createEarth, createImageMarker, drawBoundary, SABorder } from '../../utils/3dUIObjects';

function keyById(objs) {
  return objs.reduce((obj, x) => Object.assign(obj, { [x.id]: x }), {});
}

/* Globals */
var earth = null;
var lastRef = null;
var controls = null;
var scene = null;

function *startRenderSaga() {

  const ref = yield select(getRef);
  const cameraTest = yield select(getCamera);

  if (ref !== null && cameraTest === null && lastRef != ref) {
  
    lastRef = ref;
  
    // Get cameras
    var allCameras = yield getFromApi('cameras');
    yield put(setVisibleCameras(allCameras.cameras));
    
    // Get boundaries
    var allBoundaries = yield getFromApi('boundaries');
    yield put(setBoundaries(allBoundaries.boundaries));
    
    // Get nearby images and determine their position in earth coords.
    var allImages = yield getFromApi('images');
    allImages.images.forEach((_, i) => {
      var X = lonLatAltToVector3(_.long, _.lat, _.alt/1000 + 6357);
      _.x = X.x;
      _.y = X.y;
      _.z = X.z;
      });
      
    yield put(setVisibleImages(allImages.images));
  
    scene = new THREE.Scene();
    
    // Create a camera, and set up the orbit controls
    var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 30000 );

    controls = new OrbitControls(camera, ref);
    controls.target.set(0, 0, 0);
    controls.update();
    
    if (allImages.images.length == 0) {
      camera.position.x = 0;
      camera.position.y = 0;
      camera.position.z = 10000;    
    }
    else {
      camera.position.x = allImages.images[0].x * 1.5;
      camera.position.y = allImages.images[0].y * 1.5;
      camera.position.z = allImages.images[0].z * 1.5; 
    }
    
    controls.target.set(0, 0, 0);
    controls.update();
    
    // Create and set up the renderer
    var renderer = new THREE.WebGLRenderer();
    renderer.setSize( window.innerWidth, window.innerHeight );
  
    ref.appendChild( renderer.domElement );
    
    yield put(setScene(scene));
    yield put(setCamera(camera));
    
    // Draw the earth
    earth = createEarth(scene);
    
    // Draw the countries
    // drawCountry(SABorder, scene);
    
    // Draw relevant images
    for (var k = 0; k < allImages.images.length; k++) {
      var _ = allImages.images[k];
      createImageMarker({x: _.x, y: _.y, z: _.z}, scene);
    }
    
    camera.readyAndWilling = false;
    
    // Basic Translation
    document.goingForward = false;
    document.goingBackward = false;
    document.goingLeft = false;
    document.goingRight = false;
    document.goingUp = false;
    document.goingDown = false;
    
    // Basic Rotation
    document.turningDown = false;
    document.turningUp = false;
    document.turningLeft = false;
    document.turningRight = false;
    
    var animate = function () {
    
      // Execute translations
      if (document.goingForward) {
        camera.position.z -= 0.1;
        
         // ctx.drawImage(img, 0, 0);
        // texture.needsUpdate = true;
      }
      
      if (document.goingBackward) {
        camera.position.z += 10;
      }
      
      if (document.goingLeft) {
        camera.position.x -= 0.1;
      }
      
      if (document.goingRight) {
        camera.position.x += 0.1;
      }
      
      if (document.goingUp) {
        camera.position.y += 0.1;
      }
      
      if (document.goingDown) {
        camera.position.y -= 0.1;
      }
    
      // Execute Rotations
       if (document.turningDown) {
        camera.rotation.x -= 0.02;
      }
      
      if (document.turningUp) {
        camera.rotation.x += 0.02;
      }
      
      if (document.turningLeft) {
        camera.rotation.y -= 0.02;
      }
      
      if (document.turningRight) {
        camera.rotation.y += 0.02;
      }
    
      // Only update camera position on server if having moved and camera is already fetched from home
      if ((document.goingForward ||
        document.goingBackward ||
        document.goingLeft ||
        document.goingRight ||
        document.goingUp ||
        document.goingDown ||
        document.turningDown ||
        document.turningUp ||
        document.turningLeft ||
        document.turningRight) &&
          camera.readyAndWilling) {
      
        nonSagaPutToApi('cameras', { id: 1, 
          name: "main",
          x: camera.position.x, 
          y: camera.position.y, 
          z: camera.position.z,
          
          wx: 0, 
          wy: 1, 
          wz: 0,
          });
        
      }
    
      requestAnimationFrame( animate );
      renderer.render( scene, camera );
    
    };
    
    // Get camera initial position
    var cameraPosition = yield getFromApi('cameras?id=1');
    
    /*
    if (cameraPosition.cameras.length > 0) {
      camera.position.x = cameraPosition.cameras[0].x;
      camera.position.y = cameraPosition.cameras[0].y;
      camera.position.z = cameraPosition.cameras[0].z;
      camera.readyAndWilling = true;
      
      var position = { 
        x: Math.round(camera.position.x * 100) / 100, 
        y: Math.round(camera.position.y * 100) / 100,
        z: Math.round(camera.position.z * 100) /100,
        rx: Math.round(camera.rotation.x * 100) / 100, 
        ry: Math.round(camera.rotation.y * 100) / 100,
        rz: Math.round(camera.rotation.z * 100) /100 };
        yield put(setPosition(position));
    }
    */
    
    animate();
    
    // Set boundary to first
    if (allBoundaries.boundaries.length > 0) {
      yield put(setSelectedBoundaryId(allBoundaries.boundaries[0].id));
    }
  }
}

function *setSelectedBoundaryIdSaga(action) {
  if (scene != null) {
    const selectedboundaryId = yield select(getSelectedBoundaryId);
  
    var boundaryResponse = yield getFromApi('boundaries?id=' + selectedboundaryId);
    var boundaryPoints = boundaryResponse.boundary.coordinates;
  
    drawBoundary(boundaryPoints, scene);
  }
}

function *handleKeyDownEventSaga(action) {

  const consoleLive = yield select(getConsoleLive);

  if (!consoleLive) {

    if (action.payload.key == 'w' || action.payload.key == 'W') {
    
      if (!action.payload.shiftKey) {
        document.goingForward = true;
        document.goingBackward = false;
      }
      else {
        document.turningDown = true;
        document.turningUp = false;
      }
    }
    else if (action.payload.key == 's' || action.payload.key == 'S') {
      if (!action.payload.shiftKey) {
        document.goingForward = false;
        document.goingBackward = true;
      }
      else {
        document.turningDown = false;
        document.turningUp = true;
      }
    }
  
    if (action.payload.key == 'a' || action.payload.key == 'A') {
      if (!action.payload.shiftKey) {
        document.goingLeft = true;
        document.goingRight = false;
      }
      else {
        document.turningLeft = true;
        document.turningRight = false;
      }
    }
    else if (action.payload.key == 'd' || action.payload.key == 'D') {
      if (!action.payload.shiftKey) {
        document.goingLeft = false;
        document.goingRight = true;
      }
      else {
        document.turningLeft = false;
        document.turningRight = true;
      }
    }
    if (action.payload.key == 'q' || action.payload.key == 'Q') {
      document.goingUp = true;
      document.goingDown = false;
    }
    else if (action.payload.key == 'c' || action.payload.key == 'C') {
      document.goingUp = false;
      document.goingDown = true;
    }
  }
}

function *handleKeyUpEventSaga(action) {

  const camera = yield select(getCamera);  
  
  const consoleLive = yield select(getConsoleLive);

  if (!consoleLive) {
    if (action.payload.key == 'w' || action.payload.key == 'W') {
      document.goingForward = false;
      document.turningDown = false;
    }
    else if (action.payload.key == 's' || action.payload.key == 'S') {
      document.goingBackward = false;
      document.turningUp = false;
    }
    else if (action.payload.key == 'a' || action.payload.key == 'A') {
      document.goingLeft = false;
      document.turningLeft = false;
    }
    else if (action.payload.key == 'd' || action.payload.key == 'D') {
      document.goingRight = false;
      document.turningRight = false;
    }
    else if (action.payload.key == 'q' || action.payload.key == 'Q') {
      document.goingUp = false;
    }
    else if (action.payload.key == 'c' || action.payload.key == 'C') {
      document.goingDown = false;
    }
    else if (action.payload.key == ' ') {
      yield call(postToApiBlock,'points', { 
        x: camera.position.x,
        y: camera.position.y,
        z: camera.position.z,
        long: 0,
        lat: 0,
        alt: 0,
        u: 0,
        v: 0,
        r: 0, 
        g: 0,
        b: 0,
        user_id: 1,
        sector: 0});   
    }
  
    var position = { 
      x: Math.round(camera.position.x * 100) / 100, 
      y: Math.round(camera.position.y * 100) / 100,
      z: Math.round(camera.position.z * 100) / 100,
      rx: Math.round(camera.rotation.x * 100) / 100, 
      ry: Math.round(camera.rotation.y * 100) / 100,
      rz: Math.round(camera.rotation.z * 100) / 100 };
    yield put(setPosition(position));
  }
}

export default function*() {
  //yield takeLatest(startRender.Type, startRenderSaga);
  //yield takeLatest(handleKeyDownEvent.Type, handleKeyDownEventSaga);
  //yield takeLatest(handleKeyUpEvent.Type, handleKeyUpEventSaga);
  //yield takeLatest(setSelectedBoundaryId.Type, setSelectedBoundaryIdSaga);
}
