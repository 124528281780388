import { put } from 'redux-saga/effects';
import { push } from '../../global-state/toasts/reducer';
import { logOut } from '../../global-state/authentication/reducer';
import { select } from "redux-saga/effects"

function* deleteFromApi(route) {

  const token = yield select(state => state.Authentication.token);

  const response = yield fetch(document.homeAddress + `${route}`, {
    method: 'DELETE',
    headers: { 'Accept' : 'application/json', 'Content-Type': 'application/json;', 'Authorization': 'Bearer ' + token },
    credentials: 'include'
  })
    // .catch(err => console.error('error', err))
    .then(r => {
      if (r.ok) {
        return r.json();
      }
      return { $error: r.status };
    });
  if (response.$error) {
    if (response.$error === 401 || response.$error === 403) {
      yield put(
        push({
          type: 'Toasts/PUSH',
          payload: { body: 'You have been logged out', style: 'warning' }
        })
      );
      yield put(logOut());
    }
    throw Error();
  }
  return response;
}
export default deleteFromApi;
