import { connect } from 'react-redux';
import { fetchFromApi, getView, setView, getGotData,
  getCertificates, getStatuses, getTimerValue, setTimerFunc, getTimerFunc, getTimer, setTimer,
  } from './reducer';
import Component from './components/certificates';

export default connect(
  (state, ownProps) => ({
    certificates: getCertificates(state),
    statuses: getStatuses(state),
    view: getView(state),
    gotData: getGotData(state),
    timerValue: getTimerValue(state),
  }),
  dispatch => ({ 
  	fetchFromApi: () => dispatch(fetchFromApi(1)), 
  	setView: (view) => dispatch(setView(view)),
  	setTimer: (timer) => dispatch(setTimer(timer)),
  	setTimerFunc: (timer) => dispatch(setTimerFunc(timer)),
  })
)(Component);
