import { connect } from 'react-redux';

import { getAll } from '../../global-state/toasts/reducer';
import Component from './components';

export default connect(
  state => ({
    toasts: getAll(state)
  })
  // dispatch => ({})
)(Component);
