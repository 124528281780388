import { connect } from 'react-redux';

import { getIsLoggedIn, logOut } from '../../global-state/authentication/reducer';
import Component from './components';

export default connect(
  state => ({
    isLoggedIn: getIsLoggedIn(state)
  }),
  dispatch => ({ logOut: () => dispatch(logOut()) })
)(Component);
