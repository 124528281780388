import { takeLatest, takeEvery, call, select, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { fetchFromApi, loadChannels } from './reducer';
import { push } from '../../global-state/toasts/reducer';
import getFromApi from '../../utils/fetch/get-from-api';
import postToApi from '../../utils/fetch/post-to-api';
import deleteFromApi from '../../utils/fetch/delete-from-api';

function keyById(objs) {
  return objs.reduce((obj, x) => Object.assign(obj, { [x.id]: x }), {});
}

function* fetchFromApiSaga(action) {
  try {
    const response = yield call(getFromApi, `channels`);

    yield put(loadChannels(keyById(response.channels)));
  } catch (err) {
    yield put(push({ body: 'Failed to fetch channel data', style: 'error' }));
  }
}

export default function*() {
  yield takeLatest(fetchFromApi.Type, fetchFromApiSaga);
}
