import createAction from '../../utils/redux/create-action';
import createSimpleReducer from '../../utils/redux/create-simple-reducer';

export const name = 'Navigation';
export const reducer = createSimpleReducer(
  name,
  {
    entry: null,
    location: null
  },
  {
    URL_CHANGE: (s, p) => ({ ...s, location: p }),
    URL_ENTRY: (s, p) => ({ ...s, entry: p })
  }
);
export default reducer;

export const getEntryLocation = state => state[name].entry;
export const redirectTo = createAction('URL', 'PUSH');
