import createSimpleReducer from '../../utils/redux/create-simple-reducer';
import createAction from '../../utils/redux/create-action';

export const name = 'Authentication';

export const logIn = createAction(name, 'LOGGED_IN');
export const logOut = createAction(name, 'LOGGED_OUT');

export const getIsLoggedIn = state => state[name].isLoggedIn;
export const getToken = state => state[name].token;
export const getLoggedInUser = state => state[name];

export const reducer = createSimpleReducer(
  name,
  {
    isLoggedIn: false,
  },
  {
    [logIn.Type]: (s, p) => Object.assign({}, s, { isLoggedIn: true, 
                                                    token: p.token, 
                                                    }),
    [logOut.Type]: s => Object.assign({}, s, { isLoggedIn: false, token: '' })
  }
);
export default reducer;
