import * as React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import { DragDropContext } from 'react-beautiful-dnd';

import withComponentDidMount from '../../../utils/react/with-component-did-mount';
import withComponentDidUnmount from '../../../utils/react/with-component-did-unmount';

const Repositories = (props) => {

  var list = Object.values(props.repositories).sort((a, b) => (a.name > b.name) ? 1 : -1);
  
  console.log(list);
  
  const columns = [
  {
    Header: 'Repository',
    accessor: 'name' // String-based value accessors!
  },
  {
    Header: 'Site Address',
    accessor: 'address' // String-based value accessors!
  },
  ];


  return (
  <section className="section">
   
    <main className="repositories">
       
      <div>
      
        <ReactTable
          data={list}
          columns={columns}
          defaultPageSize={100}
          getTrProps={(state, rowInfo, column) => {
            return {
              style: {
                background: "#00000000"
              }
            }
          }}
        />
      </div>
    </main>
  </section>
  );
};

export default withComponentDidUnmount(props => { clearInterval(props.timer); })(withComponentDidMount(props => { props.fetchFromApi(); props.setTimerFunc(() => { props.fetchFromApi(); console.log('Refresh');} ); props.setTimer(setInterval(() => {props.fetchFromApi(); console.log('Refresh');}, 1000 * parseInt(props.timerValue)));})(Repositories));

