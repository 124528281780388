import { connect } from 'react-redux';
import { actions, selectors } from './reducer';
import Component from './components';
import withComponentDidMount from '../../../../utils/react/with-component-did-mount';
import { setEditChannelId, getPopupLoading, setPopupLoading, setNewChannel, 
  getNewChannel, getEditChannelId } from '../../reducer';

export default connect(
  (state, ownProps) =>   
    Object.assign({}, selectors.getAll(state), {
      id: ownProps.id,
      newChannel: getNewChannel(state),
      editChannelId: getEditChannelId(state),
      popupLoading: getPopupLoading(state),
      dirtyKeys: selectors.dirtyKeys(state)
    }),
  (dispatch) => ({
    request: () => dispatch(actions.request()),
    update: (key, value) => dispatch(actions.update(key, value)),
    submit: () => dispatch(actions.submit()),
    setEditChannelId: () => dispatch(setEditChannelId()),
    setNewChannel: (value) => dispatch(setNewChannel(value)),
    setPopupLoading: () => dispatch(setPopupLoading()),
  })
)(withComponentDidMount(_ => _.request())(Component));
