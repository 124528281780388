import * as React from 'react';

const L2 = (props) => {

  const word = props.word;
  const figColor = props.color;
  // console.log(word);



  if (word.length == 0) {
    return (<p>?</p>);
  }

  if (!isNaN(word)) {
    const lenW = word.length;
    
    const digits = "" + lenW;
    
    const lenD = digits.length;
   
    const m = "" + lenD;
    
    const lenM = m.length;
   
    const colors = ['black', 'brown', 'red', 'orange', 'yellow', 'green', 'blue', 'purple', 'gray', 'white'];
        
    return (
      <svg style={{marginLeft: "5px"}} height="50" width="50" viewBox="0 0 20 20">
        <circle r="10" cx="10" cy="10" fill={ figColor } />
        
        { digits.split('').map( (_, i) => 
          <circle key={'a' + i } r={4.8} cx="10" cy="10" fill={"#00000000"}
            stroke={colors[parseInt(_)]}
            strokeWidth="10"
            strokeDasharray={ [ ( (100/lenD + (lenD - (i + 1)) * 100/lenD) * 30.16 / 100), 30.16 ]} 
            transform="rotate(-90) translate(-20)"/> 
          )
        }
    
        { word.split('').map( (_, i) => 
          <circle key={'b' + i } r={3.5} cx="10" cy="10" fill={"#00000000"}
            stroke={colors[parseInt(_)]}
            strokeWidth="7"
            strokeDasharray={ [ ( (100/lenW + (lenW - (i + 1)) * 100/lenW) * 21.99 / 100), 21.99 ]} 
            transform="rotate(-90) translate(-20)"/> 
          )
        }
        
        { m.split('').map( (_, i) => 
          <circle key={'b' + i } r={1} cx="10" cy="10" fill={"#00000000"}
            stroke={colors[parseInt(_)]}
            strokeWidth="2"
            strokeDasharray={ [ ( (100/lenM + (lenM - (i + 1)) * 100/lenM) * 12.57 / 100), 12.57 ]} 
            transform="rotate(-90) translate(-20)"/> 
          )
        }
      </svg> 
      );
  }

  if (word.length == 3) {
    return (
    <svg width="50" height="50" viewBox="0 0 100 100">
    
      {/* Two circles */}
      
      { word[1] != "c" ?
        <circle cx="25" cy="50" r="18" stroke={ figColor } strokeWidth="6" fill="#00000000"/>
        : 
        <circle cx="35" cy="50" r="18" stroke={ figColor } strokeWidth="6" fill="#00000000"/>
      }
      
      { word[1] != "c" ?
        <circle cx="75" cy="50" r="18" stroke={ figColor } strokeWidth="6" fill="#00000000"/>
        : 
        <circle cx="65" cy="50" r="18" stroke={ figColor } strokeWidth="6" fill="#00000000"/>
      }
      
      
      {/* Left temporal*/}
      { word[0] == "e" && word[0] == "i"?
        <line x1="25" y1="60" x2="25" y2="78" stroke={ figColor } strokeWidth="4" strokeLinecap="round"/>
        :
        ""
      }
      
      { word[0] == "i"?
        <line x1="30" y1="58" x2="40" y2="73" stroke={ figColor } strokeWidth="4" strokeLinecap="round"/>
        :
        ""
      }
      
      { word[0] == "u"?
        <line x1="20" y1="58" x2="10" y2="73" stroke={ figColor } strokeWidth="4" strokeLinecap="round"/>
        :
        ""
      }
      
      {/* Right temporal */}
      { word[2] == "e" && word[0] == "i"?
        <line x1="75" y1="60" x2="25" y2="78" stroke={ figColor } strokeWidth="4" strokeLinecap="round"/>
        :
        ""
      }
      
      { word[2] == "i" ?
        <line x1="80" y1="58" x2="25" y2="78" stroke={ figColor } strokeWidth="4" strokeLinecap="round"/>
        :
        ""
      }
      
      { word[2] == "u" ?
        <line x1="70" y1="58" x2="60" y2="73" stroke={ figColor } strokeWidth="4" strokeLinecap="round"/>
        :
        ""
      }
      
      {/* Division marker */}
      { word[1] == "s" ? 
        <line x1="50" y1="10" x2="50" y2="90" stroke={ figColor } strokeWidth="8" strokeLinecap="round"/>
        :
        ""
      }
      
    </svg>
    )
  }
  
  if (word.length == 1 && word[0] == "o") {
    return (
    <svg width="50" height="50" viewBox="0 0 100 100">
    
      {/* Circles */}
      <circle cx="50" cy="50" r="28" stroke={ figColor } strokeWidth="6" fill="#00000000"/>
    </svg> );
  }
  
  return ( <p>?</p> )
};

export default L2;

