import { connect } from 'react-redux';
import { fetchFromApi, getView, setView, 
  getChannels, getTimerValue, setTimerFunc, getTimerFunc, getTimer, setTimer,
  getNewChannel, setNewChannel, getEditChannelId, setEditChannelId } from './reducer';
import Component from './components/channels';

export default connect(
  (state, ownProps) => ({
    channels: getChannels(state),
    view: getView(state),
    timerValue: getTimerValue(state),
    
    editChannelId: getEditChannelId(state),
    newChannel: getNewChannel(state)
  }),
  dispatch => ({ 
  	fetchFromApi: () => dispatch(fetchFromApi(1)), 
  	setView: (view) => dispatch(setView(view)),
  	setTimer: (timer) => dispatch(setTimer(timer)),
  	setTimerFunc: (timer) => dispatch(setTimerFunc(timer)),
  	
  	setNewChannel: (value) => dispatch(setNewChannel(value)),
  	setEditChannelId: (id) => dispatch(setEditChannelId(id)),
  })
)(Component);
