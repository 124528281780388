import * as React from 'react';

import { Link } from 'react-router-dom';

const LoginForm = props => {
  const { dirtyKeys } = props;
  const { update } = props;
  const dirtyStyle = { borderColor: 'orange' };

  function inputProps(key) {
    return {
      style: dirtyKeys.includes(key) ? dirtyStyle : null,
      value: props[key],
      onChange: event => update(key, event.target.value)
    };
  }
  function submit() {
    document.getElementById('btnSubmit').setAttribute("disabled", "disabled");
    props.submit();
  }
  return (
    <form>
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input
                className="input is-danger login-input"
                type="email"
                placeholder="EMAIL"
                {...inputProps('userName')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input
                className="input is-danger login-input"
                type="password"
                placeholder="PASSWORD"
                {...inputProps('password')}
              />
            </div>
          </div>
        </div>
      </div>

      

      <div className="level ">
        <button
          type="button"
          id="btnSubmit" 
          onClick={() => submit()}
          className="button"
        >
          SIGN IN
        </button>
      </div>
    </form>
  );
};
export default LoginForm;
