import { connect } from 'react-redux';
import { actions, selectors } from './reducer';
import Component from './components';

export default connect(
  state =>
    Object.assign(selectors.getAll(state), {
      dirtyKeys: selectors.dirtyKeys(state)
    }),
  dispatch => ({
    update: (key, value) => dispatch(actions.update(key, value)),
    reset: (key, value) => dispatch(actions.reset(key, value)),
    submit: () => dispatch(actions.submit()),
    forgotPassword: () => dispatch(actions.forgotPassword())
  })
)(Component);
