import createFormState from '../../../../utils/redux/create-form-state';
import createAction from '../../../../utils/redux/create-action';

export const name = 'LoginForm';

export const { reducer, types, actions, selectors } = createFormState(
  name,
  {
    userName: '',
    password: ''
  },
  {
    forgotPassword: createAction(name, 'forgotPassword')
  }
);

export default reducer;
