import {select} from "redux-saga/effects"

export function *patchToApi(route, body) {
 
 
  const token = yield select(state => state.Authentication.token);
 
  return (
    fetch(document.homeAddress + `${route}`, {
      method: 'PATCH',
      headers: { 'Accept' : 'application/json', 'Content-Type': 'application/json;', 'Authorization': 'Bearer ' + token },
      credentials: 'include',
      body: JSON.stringify(body)
    })
    .then(_ => _.json())
    .catch(err => console.error('error', err))
  );
}

function *patchToApiBlock(route, body) {
 
 
  const token = yield select(state => state.Authentication.token);
 
  const response = yield fetch(document.homeAddress + `api/v1/${route}`, {
      method: 'PATCH',
      headers: { 'Accept' : 'application/json', 'Content-Type': 'application/json;', 'Authorization': 'Bearer ' + token },
      credentials: 'include',
      body: JSON.stringify(body)
  })
    .then(_ => _.json())
    .catch(err => console.error('error', err))
 
  return response;
}

export default patchToApiBlock;
