import { connect } from 'react-redux';
import Component from './components/l2';

export default connect(
  (state, ownProps) => ({
    word: ownProps.word,
  }),
  dispatch => ({ 
    
  })
)(Component);
