import * as React from 'react';
import ReactTable from 'react-table';
import Terminal from 'react-console-emulator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faPencilAlt, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'

import * as THREE from "three";

import 'react-table/react-table.css';

import { DragDropContext } from 'react-beautiful-dnd';

import withComponentDidMount from '../../../utils/react/with-component-did-mount';
import withComponentDidUnmount from '../../../utils/react/with-component-did-unmount';

const Cameras = (props) => {

  var commands = {
    echo: {
      description: 'Echo a passed string.',
      usage: 'echo <string>',
      fn: function () {
        return `${Array.from(arguments).join(' ')}`
      }
    },
    cameras: {
      description: 'List the cameras in the session',
      usage: 'cameras',
      fn: function () {
        return `${ props.cameras.map(_ => {
          return 'Name: ' + _.name + ', x:' + _.x + ', y:' + _.y + ', z:' + _.z + '\n';
          }) }`;
      }
    },
    images: {
      description: 'List the images in the session',
      usage: 'images',
      fn: function () {
        return `${ props.images.map(_ => {
          return '#' + _.id + ' =  Lng: ' + _.long + ', Lat: ' + _.lat + ', Alt: ' + 
            _.alt + ', x:' + _.x + ', y:' + _.y + ', z:' + _.z + '\n';
          }) }`;
      }
    } 
  }


  return (
    <div 
      className="cameras-window"
      onKeyDown={(event) => props.handleKeyDownEvent(event)} 
      onKeyUp={(event) => props.handleKeyUpEvent(event)}
      tabIndex="-1" ref={ref => { props.setRef(ref); } }>
      
      <div className={`camera-status ${ props.consoleLive ? "go-up" : "" }
        ${ props.cameraLive ? "show" : "" }`}>
        <div className="camera-button-close"
          onClick={() => props.setCameraLive(false)}>
          <FontAwesomeIcon icon={faMinus} />
        </div>
          
        <div className="camera-status-padding">  
          <p>CAMERA</p>
          <div className="camera-status-divider" />
          <p>T X: { props.position.x }</p>
          <p>T Y: { props.position.y }</p>
          <p>T Z: { props.position.z }</p>
          <div className="camera-status-divider" />
          <p>R X: { props.position.rx }</p>
          <p>R Y: { props.position.ry }</p>
          <p>R Z: { props.position.rz }</p>
          <div className="camera-status-divider" />
          <select onChange={ (event) =>
            props.setSelectedBoundaryId(event.target.value)
            } value={ props.selectedBoundaryId }>
            { props.boundaries.map( _ => 
              <option key={ "boundary_id_" + _.id } value={ _.id }>
                { _.name }
              </option>
              )
            }
          </select>
        </div>
      </div>
      
      <div className={`terminal-section ${props.consoleLive ? "show" : ""}`}>
        <div className="console-button-close"
          onClick={() => props.setConsoleLive(false)}>
          <FontAwesomeIcon icon={faMinus} />
        </div>
        <div className="terminal-padding">
          <Terminal
            className={`terminal-inside ${props.consoleLive ? "" : "hide"}`}
            commands={commands}
            welcomeMessage={'Welcome to Ausonet Analysis Web App'}
            promptLabel={'me@Ausonet:~$'}
            />
        </div>
      </div>
      
      
      <div onClick={() => props.setConsoleLive(!props.consoleLive)}
        className={`console-button ${ props.consoleLive ? "hide" : "" }`}>
        <p><FontAwesomeIcon icon={faPlus} /></p>
      </div>
      
       <div onClick={() => props.setCameraLive(!props.cameraLive)}
        className={`camera-button ${ props.cameraLive ? "hide" : "" }`}>
        <p><FontAwesomeIcon icon={faPlus} /></p>
      </div>
      
    </div>
  );
  
  /*
  <section className="section">
   
    <main className="cameras">
      <div>
      </div>
    </main>
  </section>
  );
  */
};

export default withComponentDidUnmount(props => {  })
  (withComponentDidMount(props => props.startRender())(Cameras));

