import { connect } from 'react-redux';
import { fetchFromApi, getView, setView, 
  getLine, setLine } from './reducer';
import Component from './components/terminal';

export default connect(
  (state, ownProps) => ({
    view: getView(state),
    line: getLine(state),
  }),
  dispatch => ({ 
  	fetchFromApi: () => dispatch(fetchFromApi(1)), 
  	setLine: (line) => dispatch(setLine(line)),
  })
)(Component);
